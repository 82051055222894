<template>
    <div class="c-app flex-row align-items-center">

        <CModal
            title="Confirmation"
            color="primary"
            :centered="true"
            :show.sync="showConfirmModal"
        >
            <CAlert v-if="errorMsgs.length > 0" color="danger">
                <strong>Error: </strong>
                <ul v-for="msg in errorMsgs" :key="msg">
                <li>{{msg}}</li>
                </ul>
            </CAlert>

            <CCol sm="12" class='mb-4'>
                <span>
                    Please print your name in capital letters which will be used for your certificate so ensure it is your given name.
                </span>
            </CCol>
            <CCol sm="12">
                <CInput
                    label="Firstname"
                    v-model="confirmFirstname"
                />
            </CCol>
            <CCol sm="12">
                <CInput
                    label="Surname"
                    v-model="confirmSurname"
                />
            </CCol>
            <template #footer>
                <CButton color="success" @click="modalCancel">Cancel</CButton>
                <CButton color="danger" @click="submitDelegate">Confirm</CButton>
            </template>
        </CModal>

        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCard>
                        <CCardBody>
                            <CForm>
                                <img
                                    src="../../assets/icons/knights-logo-full.png"
                                    :height="50"
                                    class="rounded mx-auto d-block"
                                />
                                
                                <h4 class="py-3">Delegate Submission</h4>

                                <CAlert v-if='errorMsgs.length > 0' color="danger">{{ errorMsgs[0] }}</CAlert>
                                <CAlert v-if='success' color="success">Submission success</CAlert>

                                <a-form-model :model="delegate" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-model-item class="mb-0" label="Code" >
                                        <a-input v-model="delegate.code" />
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Firstname" help="Please print your name in capital letters">
                                        <a-input v-model="delegate.firstname" />
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Surname" help="Please print your name in capital letters">
                                        <a-input v-model="delegate.surname" />
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Birthdate" >
                                        <a-date-picker
                                            :format="dateFormat"
                                            v-model="delegate.birth_date"
                                            style="width: 100%;"
                                        ></a-date-picker>
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Device">
                                        <a-input v-model="delegate.device" />
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Department">
                                        <a-input v-model="delegate.department" />
                                    </a-form-model-item>
                                    <a-form-model-item class="mb-0" label="Agreed to GDPR & Recording">
                                        <a-switch v-model="delegate.gdpr_recording_agree" />
                                    </a-form-model-item>                                     
                                </a-form-model>

                                <CCol col="6" class="text-left">
                                    <CButton color="primary" class="px-4" @click="openConfirmModal"
                                    >Submit</CButton
                                    >
                                </CCol>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>  
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import moment from 'moment';

  export default {
    name: "DelegateExternalForm",
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        dateFormat: "DD-MMM-YYYY",
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
        errorMsgs: [],
        spinning: false,
        delegate: {},
        success: false,
        confirmFirstname: '',
        confirmSurname: '',
        showConfirmModal: false
      }
    },
    methods: {
        moment,
        openConfirmModal(){
            this.showConfirmModal = true;
        },
        modalCancel() {
            this.showConfirmModal = false;
        },
        async submitDelegate(){
            this.errorMsgs = [];
            if ((this.delegate.firstname == this.confirmFirstname) && (this.delegate.surname == this.confirmSurname)) {
                this.spinning = true;

                this.success = false;
                let response = await HTTPRequest.postRequest(
                    this.endPoint, 
                    "/api/v1/delegates/create_external",
                    null, 
                    this.delegate
                );
                if (response.error) {
                    this.errorMsgs.push(response.error)
                } else {
                    this.success = true;
                }
                this.showConfirmModal = false;
                this.spinning = false;
            } else {
                this.errorMsgs.push('Please double check firstname and surname input')
            }

        }
    }
  };
</script>
